.main-pic {
  width: 800px; 
  height: auto;
  display: block; 
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  filter: invert(1);
}

@media screen and (max-width: 800px) {
  .main-pic {
    width:100vw;
    height: auto;
  }

  .body2 {
    width: 90vw !important;
  }

}

body {
  background-color: rgb(28,28,30);
  color: #fff;
  font-weight: 200 !important;
}



.body2 {
  width: 700px;
  margin-left: auto;
  margin-right: auto;
}

.body2 p {
  text-align: left;
  color: 	#eeeeee;
  margin-bottom: 40px;
}

.body2 h3{
  text-align: left;

}

.body2 ul {
  margin-left: 0px;

}

p {
  color: white;
}

.main-logo {
  width: 35px;
  height: 35px;
  margin-top: 0px;
  margin-bottom: 3px;
  margin-left: 20px;
  margin-right: 5px;
}

ul  {
  display: grid;
grid-gap: 0px;
padding: 20;
margin-left: 0px;
margin-right: 0px;
grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
list-style: none;
}

.body2 ul  {

grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));

}


.body {
  margin-left: auto;
  margin-right: auto;
}
.artistUl {
  margin-left: -25px;
  margin-top: 30px;
}

.artistUl p {
  margin-bottom: 0px;
}

.artistUl li {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin-bottom: 30px;
}

img {
  
  width: 220px;
  height:220px;
  border-radius: 5px;
}

p, h3{
  text-align: center;
}



button {
  border: none;
  background-color: 	#1e1e1e;
}


.artistBody {
  text-align: center;
}



.albums img {
  width: 150px;
  height: auto;
}

.artistBody_wrap {
  text-align: center;
  margin-top: 30px;
}

.artistDes {
  width: 230px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
}

.artistDes p {
  text-align: left;
  margin-bottom: 0px;
}

.artistBody2 {
  width: 70%;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 480px) {
  .artistBody2 img {
    width: 100px;
  }

  .artistBody img {
    width: 250px;
    height: 250px;
  }

  .artistDes {
    width: 250px;
    margin-left: auto;
    margin-right: auto;
  }
}


@media screen and (max-width: 380px) {
  .artistBody2 img {
    width: 80px;
  }

  .artistBody img {
    width: 200px;
    height: 200px;
  }

  .artistDes {
    width: 200px;
    margin-left: auto;
    margin-right: auto;
  }
}

table td, table, th, tr {
  border: 1px solid rgb(94, 93, 93);
}

td {
  padding-left: 10px;
  padding-right: 10px;
}

p {
  font-weight: 150;
}


table {
  margin-left: auto;
  margin-right: auto;
  margin-top: 18px;
  width: 800px;
  margin-bottom: 30px;
}

@media screen and (max-width: 800px) {
  table {
    width: 96vw;
  }
  
  td {
    font-size: 13px;
  }
}

.album img {
  width: 350px;
  height: 350px;
}

a {
  text-decoration: none;
  color: #0070f3;
}

.album p {
  margin-top: 10px;
}

#navbarNav {
  text-align: center;
}

.body2 img {
  width: 625px;
  height: 350px;
}


@media screen and (max-width: 625px) {
  .body2 img {
    width: 98vw;
    height: 55vw;
  }

  .links {
    width: 100vw !important;
  }

}

.namu {
  margin-left: auto;
  margin-right: auto;
  display: block;
  width: 800px;
  height: auto;
  margin-top: 10px;
}

.history {
  margin-left: auto;
  margin-right: auto;
  display: block;
  width: 800px;
  height: auto;
  margin-top: 10px;
}

@media screen and (max-width: 800px) {
  .namu {
    width: 100vw;
    height: auto;
  }

  .history {
    width: 100vw;
    height: auto;
  }

}

.links {
  width: 625px;
  margin-left: auto;
  margin-right: auto;
}

.links li {
  float: left;
}


.links {
  list-style-type: circle;
}

.contact {
  margin-top: 10px;
}

.album img {
  border: 0.5px solid rgb(14, 14, 14);
}


.support {
  margin-top: 5px;
  margin-bottom: 50px;
  width: 700px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.support img {
  padding: 3px;
  width: 80px;
  height: 80px;
}


@media screen and (max-width: 700px) {
  .support {
    width: 100vw;
  }

  .support img {
    width: 70px;
    height: 70px;
  }
}

.ercfTitle {
  text-align: center;
  margin-top: 0px;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
}